import React from "react";
const BranchAwardComponent = (props) => { 
    
   
 
    if (!props.award?.Image) {
        return null;
    }
    
    return (
        <div className="branch-award">
            {props.award?.Link && props.award?.Link != "#" ?
                <a href={props.award?.Link} target="_blank">
                    <img src={props.award?.Image?.url} alt={props.award?.Image?.alternativeText} />
                </a>
                :
                <img src={props.award?.Image?.url} alt={props.award?.Image?.alternativeText} />
             }  
        </div>
    );
};

export default BranchAwardComponent;
